import { useState, useEffect } from "react"
import styled from "styled-components"
import colors from "../../res/styles/colors.json"
import logo_placeholder from "../../res/img/logo_cnpi.svg"

const Image = styled.img`
    width: 100%;
    height: 350px;
    position: relative;
    border: 1px solid transparent;
    -webkit-box-shadow: 5px 5px 21px 5px rgba(0, 0, 0, 0.17);
    box-shadow: 5px 5px 21px 5px rgba(0, 0, 0, 0.17);
    border-radius: 8px;
`
const Overlay = styled.div`
    width: 100%;
    min-height: 20%;
    background-color: ${colors.primary};
    opacity: 80%;
    position: absolute;
    bottom: 0;
    border: 1px solid transparent;
    border-radius: 8px;

    & > span {
        color: ${colors.white};
    }
`

const NewsDetails = styled.div`
    width: 80%;
    height: 80%;
    color: ${colors.white};
    padding: 20px 20px;
    display: flex;
    flex-direction: column;

    & > div {
        margin-bottom: 12px;
    }
`

const Container = styled.div`
    position: relative;
`

const NewsViewer = ({ news }) => {
    const [currentNews, setCurrentNews] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentNews + 1 === news.length) setCurrentNews(0)
            else setCurrentNews(currentNews + 1)
        }, 4000)
        return () => clearInterval(interval)
    }, [currentNews, setCurrentNews, news])
    return news.length ? (
        <Container>
            <Image
                src={
                    news[currentNews] && news[currentNews].image
                        ? news[currentNews].image
                        : logo_placeholder
                }
            />
            <Overlay>
                <NewsDetails>
                    <div>{news[currentNews].title}</div>
                    <div>{news[currentNews].subtitle}</div>
                </NewsDetails>
            </Overlay>
        </Container>
    ) : null
}

export default NewsViewer
