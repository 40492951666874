import axios from "axios"
import { useEffect, useState } from "react"
import { Container, Row, Col } from "react-awesome-styled-grid"
import { TextStyle2, Title, TextStyle3 } from "../res/styles/typos"
import colors from "../res/styles/colors.json"
import { Folder, File } from "@styled-icons/boxicons-regular"
import styled from "styled-components"
import { useHistory, useParams } from "react-router-dom"

const RedFolder = styled(Folder)`
    color: ${colors.primary};
    width: 32px;
    margin-right: 10px;
`

const RedFile = styled(File)`
    color: ${colors.primary};
    width: 45px;
    margin: 0 auto;
`

const FolderItem = ({ title, history }) => {
    return (
        <Row
            align="center"
            style={{ cursor: "pointer" }}
            onClick={() => {
                history.push(`/documenti/${title}`)
            }}
        >
            <RedFolder />
            <TextStyle2>{title}</TextStyle2>
        </Row>
    )
}

const FileItem = ({ title, folder }) => {
    return (
        <div
            style={{ margin: "10px 10px", cursor: "pointer" }}
            onClick={() => {
                window.open(
                    `${process.env.PUBLIC_URL}/res/documenti/${folder}/${title}`
                )
            }}
        >
            <Col align="center" justify="center">
                <RedFile />
                <TextStyle2>{title}</TextStyle2>
            </Col>
        </div>
    )
}

const Documents = () => {
    const [documents, setDocuments] = useState({})
    const history = useHistory()
    let { element } = useParams()

    useEffect(() => {
        axios
            .post(`${process.env.PUBLIC_URL}/readFolder.php`, {
                path: "documenti",
            })
            .then((res) => {
                const { data } = res
                let obj = {}

                for (let i = 0; i < data.length; i++) {
                    Object.assign(obj, data[i])
                }
                setDocuments(obj)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    return (
        <Container>
            <Col>
                <Title>Documenti</Title>
                <div
                    style={{
                        backgroundColor: "#ececec",
                        border: "1px solid transparent",
                        borderRadius: "8px",
                        padding: "20px 20px",
                        margin: "20px 20px",
                    }}
                >
                    <Container>
                        <Row>
                            <Col lg={2} xl={2}>
                                <TextStyle3>Cartelle</TextStyle3>
                                <div
                                    style={{
                                        marginTop: "20px",
                                        maxHeight: "400px",
                                        height: "100%",
                                        width: "100%",
                                        overflow: "scroll",
                                        padding: "10px",
                                    }}
                                >
                                    {documents
                                        ? Object.keys(documents).map(
                                              (document, index) => {
                                                  return (
                                                      <>
                                                          <FolderItem
                                                              title={document}
                                                              history={history}
                                                          />
                                                          {index ===
                                                          documents.length -
                                                              1 ? null : (
                                                              <hr />
                                                          )}
                                                      </>
                                                  )
                                              }
                                          )
                                        : null}
                                </div>
                            </Col>
                            <Col lg={10} xl={10}>
                                <TextStyle3>Files</TextStyle3>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        maxHeight: "400px",
                                        height: "100%",
                                        overflow: "scroll",
                                    }}
                                >
                                    {element && documents && documents[element]
                                        ? documents[element].map((document) => {
                                              return (
                                                  <FileItem
                                                      folder={element}
                                                      title={document}
                                                  />
                                              )
                                          })
                                        : null}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Col>
        </Container>
    )
}

export default Documents
