import { useState } from "react"
import { Container, Row, Col } from "react-awesome-styled-grid"
import Button from "../components/Button"
import Input from "../components/Form/Input"
import TextArea from "../components/Form/TextArea"
import { TextStyle, Title, TextStyle3 } from "../res/styles/typos"
import styled from "styled-components"
import { baseurl } from "../config"
import axios from "axios"

const ButtonContainer = styled.div`
    width: 40%;
    margin: 10px auto 20px auto;
`

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;

    & > span {
        margin: 20px 20px;
    }
`

const Contacts = () => {
    const [form, setForm] = useState({
        name: "",
        email: "",
        message: "",
    })

    const onChange = (e) => {
        e.preventDefault()
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (form.name === "" || form.email === "" || form.message === "") {
            return alert("Perfavore riempi tutti i campi")
        }

        axios
            .post(`${baseurl}/mailsender.php`, {
                from: form.email,
                name: form.name,
                body: form.message,
            })
            .then((res) => {
                if (res.data.status) {
                    return alert("Messaggio Inviato")
                }
                return alert("Errore durante l'invio del messaggio")
            })
            .catch((err) => alert("Errore durante l'invio del messaggio"))
    }

    return (
        <Container>
            <Row>
                <Col md={4} lg={6} xl={6} justify="center">
                    <TextContainer>
                        <Title>Contatti</Title>
                        <TextStyle3>
                            Il Consiglio dei Periti Industriali di Prato sarà
                            lieto di rispondere alle vostre richieste di
                            informazioni. Compila il form per inoltrare la tua
                            richiesta.
                        </TextStyle3>
                    </TextContainer>
                </Col>
                <Col md={4} lg={6} xl={6} justify="center" align="center">
                    <form onSubmit={onSubmit}>
                        <Row>
                            <Col>
                                <Input
                                    placeholder="Nome"
                                    name="name"
                                    type="text"
                                    value={form.name}
                                    onChange={onChange}
                                />
                                <Input
                                    placeholder="Email"
                                    name="email"
                                    type="email"
                                    value={form.email}
                                    onChange={onChange}
                                />
                            </Col>
                            <Col>
                                <TextArea
                                    placeholder="Messaggio"
                                    name="message"
                                    value={form.message}
                                    onChange={onChange}
                                />
                            </Col>
                        </Row>
                        <ButtonContainer>
                            <Button>
                                <TextStyle>Contattaci</TextStyle>
                            </Button>
                        </ButtonContainer>
                    </form>
                </Col>
            </Row>
        </Container>
    )
}

export default Contacts
