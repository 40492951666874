import { BrowserRouter, Switch, Route } from "react-router-dom"
import { pathnames } from "./config"
import Home from "./pages/Home"
import Contacts from "./pages/Contacts"
import Documents from "./pages/Documents"
import Order from "./pages/Order"
import Footer from "./components/Footer"
import Navbar from "./components/Navbar"

const Router = () => {
    return (
        <BrowserRouter>
            <Navbar />
            <Switch>
                <Route exact path={pathnames[0]} component={Home} />
                <Route path={`${pathnames[1]}/:element*`} component={Order} />
                <Route
                    path={`${pathnames[2]}/:element*`}
                    component={Documents}
                />
                <Route exact path={pathnames[3]} component={Contacts} />
            </Switch>
            <Footer />
        </BrowserRouter>
    )
}

export default Router
