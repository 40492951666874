import styled from "styled-components"
import colors from "../res/styles/colors.json"
import { TextStyle4 } from "../res/styles/typos"
import { Container, Row, Col } from "react-awesome-styled-grid"
import ContactInfo from "./ContactInfo"
import { Phone, Mail, LocationOn } from "@styled-icons/material/"
import { Fax } from "@styled-icons/fa-solid/Fax"
import { ClockFill, CalendarDateFill } from "@styled-icons/bootstrap"

const Background = styled.div`
    background-color: ${colors.secondary};
    padding-top: 20px;
    padding-bottom: 20px;
`

const Footer = () => {
    return (
        <Background>
            <Container>
                <Row align="flex-start" justify="center">
                    <Col xs={4} md={2} lg={4} xl={4}>
                        <ContactInfo>
                            <Phone />
                            <a
                                style={{ textDecoration: "none" }}
                                target="_blank"
                                rel="noreferrer"
                                href="tel:+390574442829"
                            >
                                <TextStyle4>0574442829</TextStyle4>
                            </a>
                        </ContactInfo>
                        <ContactInfo>
                            <Mail />
                            <a
                                style={{ textDecoration: "none" }}
                                target="_blank"
                                rel="noreferrer"
                                href="mailto:collegiodiprato@pec.cnpi.it"
                            >
                                <TextStyle4>
                                    collegiodiprato@pec.cnpi.it
                                </TextStyle4>
                            </a>
                        </ContactInfo>
                        <ContactInfo>
                            <LocationOn />
                            <a
                                style={{ textDecoration: "none" }}
                                target="_blank"
                                rel="noreferrer"
                                href="https://goo.gl/maps/2KgjowbdoWBCJMVJ8"
                            >
                                <TextStyle4>
                                    Via Pugliesi 26, 59100 Prato
                                </TextStyle4>
                            </a>
                        </ContactInfo>
                    </Col>
                    <Col
                        xs={4}
                        md={2}
                        lg={4}
                        xl={4}
                        align={{ xs: "flex-start", md: "center" }}
                    >
                        <div>
                            <ContactInfo>
                                <Mail />
                                <a
                                    style={{ textDecoration: "none" }}
                                    target="_blank"
                                    rel="noreferrer"
                                    href="mailto:info@peritiprato.it"
                                >
                                    <TextStyle4>info@peritiprato.it</TextStyle4>
                                </a>
                            </ContactInfo>
                            <ContactInfo>
                                <Fax />
                                <a
                                    style={{ textDecoration: "none" }}
                                    target="_blank"
                                    rel="noreferrer"
                                    href="tel:+390574444340"
                                >
                                    <TextStyle4>0574444340</TextStyle4>
                                </a>
                            </ContactInfo>
                        </div>
                    </Col>
                    <Col
                        xs={4}
                        md={2}
                        lg={4}
                        xl={4}
                        align={{ xs: "flex-start", md: "flex-end" }}
                    >
                        <div>
                            <ContactInfo>
                                <ClockFill />
                                <TextStyle4>Orari di Apertura:</TextStyle4>
                            </ContactInfo>
                            <ContactInfo>
                                <CalendarDateFill />
                                <TextStyle4>Lun: 17:00 - 19:00</TextStyle4>
                            </ContactInfo>
                            <ContactInfo>
                                <CalendarDateFill />
                                <TextStyle4>
                                    Mar - Ven: 10:00 - 13:00
                                </TextStyle4>
                            </ContactInfo>
                        </div>
                    </Col>
                </Row>
                <Row justify="center">
                    <TextStyle4>
                        C.F. 92051690482<br></br>
                        Copyright © 2021 Periti Industriali di Prato
                    </TextStyle4>
                </Row>
            </Container>
        </Background>
    )
}

export default Footer
