import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "react-awesome-styled-grid"
import { TextStyle2, Title } from "../res/styles/typos"
import { Link, useParams } from "react-router-dom"
import styled from "styled-components"
import axios from "axios"

const CategoryContainer = styled.div`
    & li {
        margin: 10px 0px;
    }
`

const readRecursive = (tree, parent) => {
    if (tree) {
        return tree.map((el) => {
            if (el) {
                if (typeof el == "object") {
                    let link = parent
                        ? `/ordine/${parent}/${Object.keys(el)[0]}`
                        : `/ordine/${Object.keys(el)[0]}`
                    return (
                        <CategoryContainer>
                            <Link
                                style={{
                                    color: "black",
                                }}
                                to={link}
                            >
                                <li>
                                    <TextStyle2>{Object.keys(el)}</TextStyle2>
                                </li>
                            </Link>
                            <ul>
                                {readRecursive(
                                    el[Object.keys(el)[0]],
                                    (parent ? parent + "/" : "") +
                                        Object.keys(el)[0]
                                )}
                            </ul>
                        </CategoryContainer>
                    )
                }
            }
            return null
        })
    }
}

const returnDocs = (tree, path, depth) => {
    if (tree && path) {
        return tree
            .map((el) => {
                if (el) {
                    if (
                        typeof el == "object" &&
                        path[depth] === Object.keys(el)[0]
                    ) {
                        return returnDocs(
                            el[Object.keys(el)[0]],
                            path,
                            depth + 1
                        )
                    }
                    if (typeof el == "object") return null
                    return el
                }
                return null
            })
            .filter((el) => el !== null)
            .flat(2)
    }
}

const Order = () => {
    let { element } = useParams()
    const [order, setOrder] = useState([])
    let path = []
    if (element) path = element.split("/")

    useEffect(() => {
        axios
            .post(`${process.env.PUBLIC_URL}/readFolder.php`, {
                path: "ordine",
            })
            .then((res) => {
                const { data } = res
                setOrder(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])
    return (
        <Container>
            <Col>
                <Title>Ordine Trasparente</Title>
                <Row align="flex-start">
                    <Col lg={3} xl={3}>
                        <ul>{readRecursive(order, null)}</ul>
                    </Col>
                    <Col lg={9} xl={9}>
                        <TextStyle2>{path[path.length - 1]}</TextStyle2>
                        <ul>
                            {order
                                ? returnDocs(order, path, 0).map((el) => {
                                      if (el && el.length !== 0) {
                                          return (
                                              <li
                                                  style={{
                                                      marginBottom: "5px",
                                                  }}
                                              >
                                                  <a
                                                      style={{ color: "black" }}
                                                      href={`${
                                                          process.env.PUBLIC_URL
                                                      }/res/ordine/${path.join(
                                                          "/"
                                                      )}/${el}`}
                                                  >
                                                      {el}
                                                  </a>
                                              </li>
                                          )
                                      }
                                      return null
                                  })
                                : null}
                        </ul>
                    </Col>
                </Row>
            </Col>
        </Container>
    )
}

export default Order
