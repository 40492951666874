import { Row } from "react-awesome-styled-grid"
import styled from "styled-components"

const Contact = styled.div`
    margin: 10px 10px;
    & > svg {
        width: 32px;
        margin-right: 10px;
        color: white;
    }
`

const ContactInfo = (props) => {
    return (
        <Row>
            <Contact>{props.children}</Contact>
        </Row>
    )
}

export default ContactInfo
