import { Row, Col } from "react-awesome-styled-grid"
import NewsItem from "./NewsItem"
import logo_placeholder from "../../res/img/logo_complete.png"

const NewsList = (props) => {
    return (
        <div>
            <Row>
                {props.news.map((el) => {
                    const image = el.image !== "" ? el.image : logo_placeholder
                    return (
                        <Col
                            xs={2}
                            sm={2}
                            md={2}
                            lg={4}
                            xl={4}
                            key={`id_${el.title}_${el.subtitle}`}
                        >
                            <NewsItem
                                title={el.title}
                                subtitle={el.subtitle}
                                links={el.links}
                                image={image}
                            />
                        </Col>
                    )
                })}
            </Row>
        </div>
    )
}

export default NewsList
