import { useEffect, useState } from "react"
import axios from "axios"
import { Container, Row, Col } from "react-awesome-styled-grid"
import Usefulinks from "../components/Usefulinks"
import { NewsViewer, NewsList } from "../components/News"
import { Subtitle } from "../res/styles/typos"
import { ThemeProvider } from "styled-components"

const Home = () => {
    const homeConf = {
        mediaQuery: "only screen",
        container: {
            xs: "full",
            sm: "full",
            md: 60,
            lg: 60,
            xl: 60,
        },
    }

    const [news, setNews] = useState([])

    const getDescription = (title) => {
        return new Promise((res) => {
            fetch(`${process.env.PUBLIC_URL}/res/news/${title}/Descrizione.txt`)
                .then((r) => r.text())
                .catch((err) => {
                    console.log(`errore ${err}`)
                })
                .then((text) => {
                    return res(text)
                })
        })
    }

    useEffect(() => {
        axios
            .post(`${process.env.PUBLIC_URL}/readFolder.php`, { path: "news" })
            .then((res) => {
                const { data } = res

                const arr = data.map(async (el) => {
                    const title = Object.keys(el)
                    if (title) {
                        const imageName = el[title].find((value) =>
                            /^Copertina./.test(value)
                        )
                        const filesNames = el[title].filter((value) =>
                            /(.pdf|.doc|.docx)/.test(value)
                        )
                        const isDescriptionPresent =
                            el[title].includes("Descrizione.txt")

                        const image = `${process.env.PUBLIC_URL}/res/news/${title}/${imageName}`
                        const subtitle = isDescriptionPresent
                            ? await getDescription(title)
                            : ""
                        return {
                            title,
                            subtitle,
                            links: filesNames,
                            image,
                        }
                    }
                })

                Promise.all(arr).then((result) => {
                    setNews(result)
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    return (
        <ThemeProvider theme={{ awesomegrid: homeConf }}>
            <Container>
                <Row align="flex-start">
                    <Col xs={4} md={6} lg={9}>
                        <Col align="flex-start">
                            <Subtitle>News</Subtitle>
                        </Col>
                        <Col>
                            <NewsViewer news={news} />
                        </Col>
                        <Col>
                            <NewsList news={news} />
                        </Col>
                    </Col>
                    <Col xs={4} md={2} lg={3}>
                        <Col align="flex-start">
                            <Subtitle>Links Utili</Subtitle>
                        </Col>
                        <Col>
                            <Usefulinks />
                        </Col>
                    </Col>
                </Row>
            </Container>
        </ThemeProvider>
    )
}

export default Home
