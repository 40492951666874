import { TextStyle2, TextStyle3 } from "../../res/styles/typos"
import styled from "styled-components"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 25px 0px;

    & > span {
        margin: 5px 0px;
    }
`

const ImagePlaceholder = styled.img`
    width: 100%;
    height: 100px;
    position: relative;
    border: 1px solid transparent;
    -webkit-box-shadow: 5px 5px 21px 5px rgba(0, 0, 0, 0.17);
    box-shadow: 5px 5px 21px 5px rgba(0, 0, 0, 0.17);
    border-radius: 8px;
    object-fit: cover;
    margin-bottom: 10px;
`

const NewsItem = (props) => {
    return (
        <Container>
            <ImagePlaceholder src={props.image}></ImagePlaceholder>
            <TextStyle2>{props.title}</TextStyle2>
            <TextStyle3>{props.subtitle}</TextStyle3>
            {props.links.map((path, index) => {
                return (
                    <a
                        href={`${process.env.PUBLIC_URL}/res/news/${props.title}/${path}`}
                        key={`link-${index}`}
                        style={{
                            color: "black",
                            textDecoration: "underline",
                        }}
                    >
                        {path}
                    </a>
                )
            })}
        </Container>
    )
}

export default NewsItem
