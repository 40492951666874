import styled from "styled-components"
import colors from "../res/styles/colors.json"

const BB = styled.button`
    width: 100%;
    padding: 15px;
    background-color: ${colors.primary};
    color: ${colors.white};
    border: 1px solid transparent;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

const Button = (props) => {
    return (
        <>
            <BB onClick={props.onClick}>{props.children}</BB>
        </>
    )
}

export default Button
