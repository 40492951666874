import { Lifebuoy } from "@styled-icons/icomoon/Lifebuoy"
import { Library } from "@styled-icons/icomoon/Library"
import { IdCard } from "@styled-icons/boxicons-regular/IdCard"
import { Document } from "@styled-icons/fluentui-system-filled/Document"
import { School } from "@styled-icons/ionicons-sharp/School"
import { Work } from "@styled-icons/material/Work"
import styled from "styled-components"
import Button from "./Button"
import { breakpointsMax } from "../res/styles/Common"
import { useHistory } from "react-router-dom"

const List = styled.div`
    display: flex;
    flex-direction: column;

    & > Button {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    & > Button > span {
        margin: auto;
    }

    & > Button > svg {
        width: 24px;
    }

    @media ${breakpointsMax.tablet} {
        & > Button {
            margin: 10px 0px;
        }
    }
`

const onClick = (link) => {
    window.open(link)
}

const Usefulinks = () => {
    const history = useHistory()

    return (
        <List>
            <Button onClick={() => onClick("https://www.eppi.it/")}>
                <Lifebuoy />
                <span>EPPI</span>
            </Button>
            <Button onClick={() => onClick("http://www.cnpi.eu/")}>
                <Library />
                <span>CNPI</span>
            </Button>
            <Button onClick={() => onClick("https://www.albounicoperind.it/")}>
                <IdCard />
                <span>ALBO UNICO</span>
            </Button>
            <Button onClick={() => history.push("/documenti")}>
                <Document />
                <span>MODULISTICA</span>
            </Button>
            <Button
                onClick={() => onClick("https://www.fondazioneopificium.it/")}
            >
                <School />
                <span>PIATTAFORMA OPIFICIUM</span>
            </Button>
            <Button
                onClick={() =>
                    onClick("http://www.palazzodelleprofessioniprato.it/")
                }
            >
                <Work />
                <span>PALAZZO DELLE PROFESSIONI</span>
            </Button>
        </List>
    )
}

export default Usefulinks
