import styled from "styled-components"
import colors from "./colors.json"

const Title = styled.span`
    font-family: Montserrat;
    font-size: 2rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${colors.black};
`

const Subtitle = styled.span`
    font-family: Montserrat;
    font-size: 1.2rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${colors.primary};
`

const TextStyle = styled.span`
    font-family: Montserrat;
    font-size: 1.2rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${colors.white};
`

const TextStyle2 = styled.span`
    font-family: Montserrat;
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${colors.black};
`

const TextStyle3 = styled.span`
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${colors.black};
`

const TextStyle4 = styled.span`
    font-family: Montserrat;
    font-size: 1rem;
    font-weight: ${(props) => (props.active ? "600" : "500")};
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${(props) => (props.color ? props.color : colors.white)};
`

export { Title, Subtitle, TextStyle, TextStyle2, TextStyle3, TextStyle4 }
