import { useState } from "react"
import styled from "styled-components"
import colors from "../res/styles/colors.json"
import { TextStyle4, TextStyle2 } from "../res/styles/typos"
import { breakpointsMax } from "../res/styles/Common"
import { Container, Row, Col } from "react-awesome-styled-grid"
import { Menu } from "@styled-icons/boxicons-regular/Menu"
import { Close } from "@styled-icons/evaicons-solid/Close"
import { useMediaQuery } from "react-responsive"
import { useLocation, Link } from "react-router-dom"
import { pathnames } from "../config"
import logo from "../res/img/logo_cnpi.svg"

const Logo = styled.img`
    width: 45px;
`
const NavItem = styled.span`
    margin: 10px 10px;
    border-bottom: ${(props) =>
        props.active ? `2px solid ${colors.primary}` : ``};
    padding-bottom: ${(props) => (props.active ? `8px` : `0px`)};
    font-family: Montserrat;
    font-size: 1rem;
    font-weight: ${(props) => (props.active ? "600" : "500")};
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${colors.gray};
`

const BlackMenu = styled(Menu)`
    color: black;
    width: 32px;
`

const BlackCross = styled(Close)`
    color: black;
    width: 32px;
`

const Items = () => {
    const page = `/${useLocation().pathname.split("/")[1]}`

    return (
        <>
            <NavItem active={page === pathnames[0]}>
                <Link style={{ textDecoration: "none" }} to={pathnames[0]}>
                    <TextStyle4
                        color={colors.gray}
                        active={page === pathnames[0]}
                    >
                        HOME
                    </TextStyle4>
                </Link>
            </NavItem>
            <NavItem active={page === pathnames[1]}>
                <Link
                    style={{ textDecoration: "none" }}
                    to={`${pathnames[1]}/`}
                >
                    <TextStyle4
                        color={colors.gray}
                        active={page === pathnames[1]}
                    >
                        ORDINE TRASPARENTE
                    </TextStyle4>
                </Link>
            </NavItem>
            <NavItem active={page === pathnames[2]}>
                <Link style={{ textDecoration: "none" }} to={pathnames[2]}>
                    <TextStyle4
                        color={colors.gray}
                        active={page === pathnames[2]}
                    >
                        DOCUMENTI
                    </TextStyle4>
                </Link>
            </NavItem>

            <NavItem active={page === pathnames[3]}>
                <Link style={{ textDecoration: "none" }} to={pathnames[3]}>
                    <TextStyle4
                        color={colors.gray}
                        active={page === pathnames[3]}
                    >
                        CONTATTI
                    </TextStyle4>
                </Link>
            </NavItem>
        </>
    )
}

const Navbar = () => {
    const isMobile = useMediaQuery({ query: breakpointsMax.tablet })
    const [isMenuOpen, setMenuOpen] = useState(false)

    return (
        <Container fluid style={{ padding: "10px 30px", marginBottom: "30px" }}>
            <Row>
                <Col justify="center">
                    <Row align="center">
                        <Logo src={logo} />
                        {!isMobile ? (
                            <TextStyle2 style={{ marginLeft: "10px" }}>
                                Ordine dei Periti Industriali e dei
                                <br />
                                Periti Industriali Laureati di Prato
                            </TextStyle2>
                        ) : null}
                    </Row>
                </Col>
                <Col justify="center" align="flex-end">
                    {isMobile ? (
                        isMenuOpen ? (
                            <BlackCross
                                onClick={() => setMenuOpen(!isMenuOpen)}
                            />
                        ) : (
                            <BlackMenu
                                onClick={() => setMenuOpen(!isMenuOpen)}
                            />
                        )
                    ) : (
                        <Row>
                            <Items />
                        </Row>
                    )}
                </Col>
            </Row>
            {isMobile && isMenuOpen ? (
                <Col>
                    <Items />
                </Col>
            ) : null}
        </Container>
    )
}

export default Navbar
