import styled from "styled-components"
import colors from "../../res/styles/colors.json"

const StyledTextArea = styled.textarea`
    height: 100%;
    border-radius: 8px;
    border: solid 1px ${colors.lightgray};
    padding: 15px 10px;
    margin: 5px;

    &:focus {
        outline: none !important;
        border: 1px solid ${colors.primary};
    }

    ::placeholder,
    ::-webkit-input-placeholder {
        color: ${colors.verylightgray};
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 300;
    }
    :-ms-input-placeholder {
        color: ${colors.verylightgray};
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 300;
    }
`

const TextArea = (props) => {
    return (
        <StyledTextArea
            placeholder={props.placeholder}
            value={props.value}
            name={props.name}
            onChange={props.onChange}
        />
    )
}

export default TextArea
